import React, { Fragment, useRef } from "react";
import game from "../../assets/images/game.png";
import home from "../../assets/images/home.png";
import close from "../../assets/images/close.png";
import refresh from "../../assets/images/refresh.png";
import { useLocation, useNavigate } from "react-router-dom";
import { trackEventTT } from "../../utils/tools";

export default function FloatNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const gameNavRef = useRef(null);
  const gameContentRef = useRef(null);

  const handleMoveRight = () => {
    if (gameNavRef.current) {
      gameNavRef.current.style.right = "-4rem";
      if (gameContentRef.current) {
        gameContentRef.current.style.bottom = "2rem";
      }
    }
  };

  const changeNav = () => {
    if (gameNavRef.current) {
      gameNavRef.current.style.right = "0";
      if (gameContentRef.current) {
        gameContentRef.current.style.bottom = "-4rem";
      }
    }
  };

  const toHome = () => {
    trackEventTT("open_game_menu");
    navigate(`/home${location.search}`);
  };

  const toRefresh = () => {
    window.location.reload();
  };

  return (
    <Fragment>
      <div
        ref={gameNavRef}
        className="w-[4rem] h-[2.6rem] fixed right-0 top-[3.75rem] transition-all duration-500 z-[999] bg-[#fff] opacity-80 rounded-bl-[4rem] rounded-tl-[4rem] flex items-center justify-center"
        onClick={handleMoveRight}
      >
        <img
          className="w-[2.5rem] h-auto object-contain"
          src={game}
          alt="gameNav"
        />
      </div>
      <div
        ref={gameContentRef}
        className="w-full overflow-hidden fixed -bottom-[4rem] left-0 z-[999] transition-all duration-500"
      >
        <div className="w-full max-w-[320px] mx-auto px-[1rem] py-[0.2rem] bg-[#fff] rounded-2xl flex flex-col justify-center opacity-80">
          <div className="flex flex-row justify-between items-center h-[3.75rem]">
            <div className="flex flex-row justify-center items-center">
              <div
                className="w-max px-[0.4rem] py-[0.4rem] border border-solid border-[#d9d9d9] shadow-2xl rounded-xl cursor-pointer"
                onClick={toHome}
              >
                <img
                  src={home}
                  alt="homeBtn"
                  className="w-[1.5rem] h-[1.5rem] object-contain"
                />
              </div>
              <div
                className="w-max px-[0.4rem] py-[0.4rem] ml-[0.5rem] border border-solid border-[#d9d9d9] shadow-2xl rounded-xl cursor-pointer"
                onClick={toRefresh}
              >
                <img
                  src={refresh}
                  alt="refreshBtn"
                  className="w-[1.5rem] h-[1.5rem] object-contain"
                />
              </div>
            </div>
            <div className="flex flex-row justify-center items-center">
              <div
                className="w-max px-[0.4rem] py-[0.4rem] border border-solid border-[#d9d9d9] shadow-2xl rounded-xl cursor-pointer"
                onClick={changeNav}
              >
                <img
                  src={close}
                  alt="closeBtn"
                  className="w-[1.5rem] h-[1.5rem] object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
