import React, { Fragment, useEffect, useMemo } from "react";
import HeaderBar from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import Footer from "../footer";
import { useAppSelector } from "../../store/hooks";
import { selectHadBannerGames } from "./homeSlice";
import MoreBtn from "../../components/MoreBtn";
import {
  HomeBottomBanner,
  HomeMidBanner,
  HomeTopBanner,
} from "../../components/Ad";
import { trackEventTT } from "../../utils/tools";

export default function Home() {
  const { search } = useLocation();
  const gameList = useAppSelector(selectHadBannerGames);

  useEffect(() => {
    trackEventTT("view_game_list");
  }, []);

  const renderGameList = useMemo(() => {
    return gameList.map((item, index) => {
      if (index === 4) {
        return (
          <div key={item.id}>
            <div
              key={item.id}
              className="w-full rounded-[0.5rem] h-[14.8rem] md:h-[17.6rem] my-[0.5rem] shadow-md overflow-hidden"
            >
              <Link
                to={`/detail/${item.id}${search}`}
                onClick={() => {
                  trackEventTT("select_game");
                  trackEventTT(`ViewContent`);
                }}
              >
                <img
                  className="w-full h-full object-cover"
                  src={item.banner}
                  alt={item.name}
                />
              </Link>
            </div>
            <HomeMidBanner />
          </div>
        );
      }
      return (
        <div
          key={item.id}
          className="w-full rounded-[0.5rem] h-[14.8rem] md:h-[17.6rem] my-[0.5rem] shadow-md overflow-hidden"
        >
          <Link
            to={`/detail/${item.id}${search}`}
            onClick={() => {
              trackEventTT("select_game");
              trackEventTT(`ViewContent`);
            }}
          >
            <img
              className="w-full h-full object-cover"
              src={item.banner}
              alt={item.name}
            />
          </Link>
        </div>
      );
    });
  }, [gameList]);

  return (
    <div className="w-full max-w-[450px] min-w-[320px] mx-auto min-h-screen px-[0.75rem] py-[0.5rem]">
      {/* remove anchor  at 2024-11-24 */}
      <HomeTopBanner />
      <HeaderBar />
      <div className="w-full mt-[0.5rem]">{renderGameList}</div>
      {/* remove bottom banner  at 2024-11-24 */}
      {/* <HomeBottomBanner /> */}
      <MoreBtn />
      <Footer />
    </div>
  );
}
